<template>
  <v-card class="ma-0 pa-0 d-block shadow-none rounded-lg" v-if="isDataLoaded">
    <v-card-title class="font-weight-bolder text-dark-65 py-2 mb-1 pt-5">{{
      item.title
    }}</v-card-title>
    <v-card-subtitle class="font-weight-bolder text-dark-25 py-2 mb-6">{{
      $t("tracking_the_status_of_incoming_orders_for_efficient_management")
    }}</v-card-subtitle>
    <v-card-text>
      <apexchart
        type="area"
        height="400"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </v-card-text>
  </v-card>
  <v-skeleton-loader v-else type="image"></v-skeleton-loader>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  name: "OrdersProgressAreaChart",
  props: ["item", "filterData", "updateChart"],
  data: () => ({ serverData: null }),
  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    loadDataFromServer() {
      this.serverData = null;
      let data = this.filterData;
      ApiService.post(`${this.item.url}`, data)
        .then((response) => {
          this.serverData = response.data.chart;
        })
        .catch(() => {});
    },
  },
  computed: {
    isDataLoaded: function () {
      return !!this.serverData;
    },
    series: function () {
      return this.serverData.values.series;
    },
    chartOptions: function () {
      return {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#AF2BF5"],
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "category",
          categories: this.serverData.values.categories,
          labels: {
            show: true,
            // formatter: function (val) {
            //   const secondDigit = /^\d+$/.test(String(val).slice(1, 2))
            //     ? String(val).slice(1, 2)
            //     : "";

            //   return (
            //     String(val).slice(-3) +
            //     " " +
            //     String(val).slice(0, 1) +
            //     secondDigit
            //   );
            // },
            style: {
              colors: "#b1b5c3",
              fontSize: "12px",
            },
          },
        },

        yaxis: {
          labels: {
            show: false,
            style: {
              colors: ["#afb2c1"],
              fontSize: "12px",
              fontWeight: 500,
            },
          },
        },

        fill: {
          colors: "#AF2BF5",
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 0.9,
            opacityFrom: 0.8,
            opacityTo: 0,
            stops: [0, 90],
            colorStops: [],
          },
        },
      };
    },
  },
  watch: {
    // filterData() {
    //   this.serverData = null;
    //   this.loadDataFromServer();
    // },
    updateChart: function (val) {
      if (val !== 0) {
        this.loadDataFromServer();
      }
    },
  },
};
</script>
